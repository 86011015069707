* {
  margin: 0;
  padding: 0;
}

#app {
  min-height: 100%;
  flex-direction: column;
  display: flex;
}

.header-container {
  border-bottom: 1px solid #edeef2;
  display: flex;
}

.header-container .header-exit-container {
  justify-content: center;
  align-items: center;
  padding-right: 24px;
  display: flex;
}

.header-container .header-nav-container {
  height: 80px;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: flex;
}

.header-container .header-nav-container .header-nav-link {
  color: #000;
}

.header-container .header-nav-container .header-nav-link:hover {
  color: #6f6f6f;
}

.login-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.login-container .login-form {
  border: 1px solid #2d78ea;
  border-radius: 12px;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  display: flex;
}

.profile-page {
  flex: 1;
  padding: 24px;
}

.profile-page .profile-page-change-password-wrapper {
  padding-top: 12px;
  padding-left: 12px;
}

.profile-page .profile-page-change-password-wrapper .profile-page-change-password-content {
  gap: 12px;
  padding-top: 12px;
  display: flex;
}

.profile-page .profile-page-change-password-wrapper .profile-page-change-password-content .profile-page-change-password-input {
  background-color: #fff;
  border: 1px solid #edeef2;
  border-radius: 12px;
  flex: 1;
  padding: 12px;
}

.users-page {
  flex: 1;
  padding: 24px;
  overflow-y: auto;
}

.users-page .users-page-filters-form {
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
  margin-bottom: 24px;
  display: flex;
}

.users-page .users-page-filters-form .users-page-filters-form-select-wrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.users-page .users-page-table-wrapper {
  overflow-x: auto;
}

.users-page .users-page-table-wrapper .users-page-table {
  width: 100%;
}

.users-page .users-page-table-wrapper .users-page-table .users-page-table-item {
  text-align: center;
}

.users-page .users-page-table-wrapper .users-page-table .users-page-table-item .users-page-table-item-cell {
  min-width: 115px;
  max-width: 160px;
  padding: 12px;
}

.shipments-page {
  flex: 1;
  padding: 24px;
  overflow-y: auto;
}

.shipments-page .shipments-page-filters-form {
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
  margin-bottom: 24px;
  display: flex;
}

.shipments-page .shipments-page-filters-form .shipments-page-filters-form-select-wrapper {
  flex-direction: column;
  padding: 6px;
  display: flex;
}

.shipments-page .shipments-page-table-wrapper {
  overflow-x: auto;
}

.shipments-page .shipments-page-table-wrapper .shipments-page-table {
  width: 100%;
}

.shipments-page .shipments-page-table-wrapper .shipments-page-table .shipments-page-table-item {
  text-align: center;
}

.shipments-page .shipments-page-table-wrapper .shipments-page-table .shipments-page-table-item .shipments-page-table-item-cell {
  min-width: 115px;
  max-width: 160px;
  padding: 12px;
}

.button-default {
  color: #fff;
  background-color: #2d78ea;
  border: 1px solid #edeef2;
  border-radius: 12px;
  padding: 12px;
}

.button-default:hover {
  cursor: pointer;
}

.button-default:disabled {
  background-color: #315ea1;
}

.input-default {
  background-color: #fff;
  border: 1px solid #edeef2;
  border-radius: 12px;
  padding: 12px;
}

.page-pagination {
  width: 100%;
  text-align: right;
  margin-top: 24px;
}

.page-pagination .page-pagination-pages-count {
  margin-left: 24px;
  margin-right: 24px;
}

.shipment-full-info {
  flex-direction: column;
  display: flex;
}

.shipment-full-info .shipment-full-info-block {
  text-align: left;
  width: calc(100% - 6px);
  border-bottom: 1px solid #edeef2;
  padding-top: 12px;
  padding-bottom: 12px;
}

.shipment-full-info .shipment-full-info-block .shipment-full-info-block-title {
  width: calc(100% - 6px);
  color: #000;
  margin-bottom: 8px;
  font-size: 20px;
  display: inline-block;
}

.shipment-full-info .shipment-full-info-block .shipment-full-info-block-text {
  width: calc(100% - 6px);
  color: #6f6f6f;
  padding-left: 12px;
  font-size: 18px;
  display: inline-block;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient( to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55 );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: Toastify__spin .65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.react-google-flight-datepicker {
  font-size: 1rem;
}

.react-google-flight-datepicker > * {
  box-sizing: border-box;
}

.react-google-flight-datepicker .date-picker-demo {
  width: 400px;
}

.react-google-flight-datepicker .btn-outline {
  cursor: pointer;
  background-color: #0000;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
}

.react-google-flight-datepicker .date-picker {
  background: #fff;
  border-radius: 4px;
  padding: 6px;
  position: relative;
}

.react-google-flight-datepicker .date-picker.disabled {
  opacity: .6;
  cursor: default;
}

.react-google-flight-datepicker .date-picker-input {
  width: 100%;
  color: #3c4043;
  height: 56px;
  letter-spacing: .2px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  display: flex;
  overflow: visible;
}

.react-google-flight-datepicker .date-picker-input svg {
  min-width: 24px;
  min-height: 24px;
}

.react-google-flight-datepicker .date-picker-date-group {
  height: 100%;
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.react-google-flight-datepicker .date {
  height: 100%;
  width: 50%;
  background: #fff;
  border-radius: 4px;
  flex-grow: 1;
  align-items: center;
  padding: 0 13px;
  display: flex;
  position: relative;
}

.react-google-flight-datepicker .date.is-single:before {
  display: none;
}

.react-google-flight-datepicker .date:first-child:before {
  content: "";
  width: 1px;
  height: 70%;
  background: #dadce0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.react-google-flight-datepicker .selected-date {
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  align-items: center;
  font-size: .9rem;
  line-height: normal;
  display: flex;
  overflow: hidden;
}

.react-google-flight-datepicker .date-placeholder {
  color: #0000008f;
}

.react-google-flight-datepicker .change-date-group {
  width: 48px;
  display: flex;
}

.react-google-flight-datepicker .change-date-group .change-date-button {
  height: 40px;
  color: #5f6368;
  cursor: pointer;
  flex-grow: 1;
  padding: 8px 0;
}

.react-google-flight-datepicker .change-date-group .change-date-button:disabled {
  cursor: default;
}

.react-google-flight-datepicker .change-date-group .change-date-button:disabled .icon-arrow {
  fill: #93989e;
}

.react-google-flight-datepicker .icon-calendar {
  width: 24px;
  height: 24px;
  fill: #1a73e8;
  margin-right: 12px;
}

.react-google-flight-datepicker .icon-calendar.mobile {
  display: none;
}

.react-google-flight-datepicker .icon-arrow {
  width: 24px;
  height: 24px;
  fill: #5f6368;
}

@keyframes showPopup {
  0% {
    opacity: 0;
    transform: scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hidePopup {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    visibility: hidden;
    transform: scale(.7);
  }
}

.react-google-flight-datepicker .dialog-date-picker {
  min-width: 200px;
  z-index: 120;
  height: max-content;
  width: 770px;
  transform-origin: 0 0;
  opacity: 0;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 50px;
  position: absolute;
  top: -2px;
  left: -2px;
  transform: scale(0);
  box-shadow: 0 1px 3px #3c40434d, 0 4px 8px 3px #3c404326;
}

.react-google-flight-datepicker .dialog-date-picker.single {
  width: 385px;
}

.react-google-flight-datepicker .dialog-date-picker.open {
  animation: showPopup .2s forwards;
}

.react-google-flight-datepicker .dialog-date-picker.hide {
  animation: hidePopup .2s forwards;
}

.react-google-flight-datepicker .dialog-header {
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 24px;
  display: flex;
}

.react-google-flight-datepicker .dialog-header .date-picker-input {
  height: 48px;
  width: 50%;
}

.react-google-flight-datepicker .date:focus, .react-google-flight-datepicker .date.is-focus {
  background-color: #f4f8ff;
}

.react-google-flight-datepicker .date:focus:after, .react-google-flight-datepicker .date.is-focus:after {
  content: "";
  pointer-events: none;
  z-index: 5;
  border: 2px solid #1a73e8;
  border-radius: 4px;
  margin: -1px;
  position: absolute;
  inset: 0;
}

.react-google-flight-datepicker .back-button {
  display: none;
}

.react-google-flight-datepicker .dialog-content {
  border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
  position: relative;
}

.react-google-flight-datepicker .reset-button {
  height: 36px;
  color: #5f6368;
  min-width: 64px;
  border: none;
  border-radius: 4px;
  padding: 0 8px;
  font-size: .9rem;
}

.react-google-flight-datepicker .reset-button:hover {
  background-color: #fafafa;
}

.react-google-flight-datepicker .reset-button.mobile {
  display: none;
}

.react-google-flight-datepicker .calendar-wrapper {
  min-height: 353px;
  margin: 12px 38px 8px;
  overflow: hidden;
}

.react-google-flight-datepicker .calendar-wrapper.single {
  margin-left: 36px;
  margin-right: 36px;
}

.react-google-flight-datepicker .calendar-content {
  padding: 0 2px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.react-google-flight-datepicker .calendar-content.isAnimating {
  overflow: initial;
  transition: transform .2s;
}

.react-google-flight-datepicker .month-calendar {
  width: 308px;
  color: #3c4043;
  margin-right: 43px;
}

.react-google-flight-datepicker .month-calendar.hidden {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.react-google-flight-datepicker .month-calendar.isAnimating {
  position: absolute;
  left: -350px;
}

.react-google-flight-datepicker .month-calendar.single {
  margin-right: 39px;
}

.react-google-flight-datepicker .month-name {
  text-align: center;
  margin-bottom: 10px;
  font-size: 1rem;
}

.react-google-flight-datepicker .weekdays {
  width: 100%;
  color: #0000008a;
  font-size: .9rem;
  display: flex;
}

.react-google-flight-datepicker .weekdays .weekday {
  height: 36px;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.react-google-flight-datepicker .week {
  justify-content: flex-start;
  display: flex;
}

.react-google-flight-datepicker .week.first {
  justify-content: flex-end;
}

.react-google-flight-datepicker .day {
  cursor: pointer;
  height: 44px;
  text-align: center;
  width: 44px;
  vertical-align: top;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 2px 0;
  display: flex;
  position: relative;
}

.react-google-flight-datepicker .day .background-day {
  opacity: 0;
  pointer-events: none;
  width: 10px;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
}

.react-google-flight-datepicker .day .background-day.first-day {
  background: linear-gradient(to right, #fff, #daedfd);
  left: -10px;
}

.react-google-flight-datepicker .day .background-day.last-day {
  background: linear-gradient(to left, #fff, #daedfd);
  right: -10px;
}

.react-google-flight-datepicker .day:after {
  content: "";
  height: 44px;
  width: 44px;
  z-index: 2;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.react-google-flight-datepicker .day:hover:after {
  background-color: #fff;
  border: 2px solid #4285f4;
  inset: -2px;
}

.react-google-flight-datepicker .day.selected {
  color: #fff;
}

.react-google-flight-datepicker .day.selected:hover {
  color: #000;
}

.react-google-flight-datepicker .day.selected:after {
  background-color: #4285f4;
  border-radius: 100%;
}

.react-google-flight-datepicker .day.selected:hover:after {
  background-color: #fff;
}

.react-google-flight-datepicker .day.highlight:before {
  content: "";
  width: 44px;
  height: 44px;
  background-color: #f7ed7ad1;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-google-flight-datepicker .day.hovered {
  border-radius: unset;
  background-color: #d9edfd;
}

.react-google-flight-datepicker .day.hovered .background-day {
  opacity: 1;
}

.react-google-flight-datepicker .day.hovered.end {
  background: linear-gradient(90deg, #d9edfd 50%, #fff 50%);
}

.react-google-flight-datepicker .day.hovered:hover {
  background-color: #d9edfd;
}

.react-google-flight-datepicker .day.hovered:hover:after {
  background-color: #fff;
  border-radius: 100%;
}

.react-google-flight-datepicker .day.selected.hovered {
  color: #fff;
  background: linear-gradient(90deg, #fff 50%, #d9edfd 50%);
}

.react-google-flight-datepicker .day.selected.hovered.end {
  background: linear-gradient(90deg, #d9edfd 50%, #fff 50%);
}

.react-google-flight-datepicker .day.selected.hovered:hover {
  color: #000;
}

.react-google-flight-datepicker .day.selected.hovered:after {
  background-color: #4285f4;
  border-radius: 100%;
}

.react-google-flight-datepicker .day.selected.hovered:hover:after {
  background-color: #fff;
}

.react-google-flight-datepicker .day.disabled {
  pointer-events: none;
  color: #00000042;
}

.react-google-flight-datepicker .day.disabled.selected {
  color: #fff;
}

.react-google-flight-datepicker .day .text-day {
  z-index: 3;
  position: relative;
}

.react-google-flight-datepicker .calendar-flippers {
  pointer-events: none;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.react-google-flight-datepicker .flipper-button {
  cursor: pointer;
  height: 40px;
  width: 40px;
  pointer-events: all;
  background-color: #fff;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 -20px;
  display: flex;
  box-shadow: 0 0 4px #0000001f, 0 4px 4px #0000003d;
}

.react-google-flight-datepicker .flipper-button svg {
  width: 24px;
  height: 24px;
}

.react-google-flight-datepicker .flipper-button.disabled {
  cursor: default;
}

.react-google-flight-datepicker .flipper-button.disabled svg {
  fill: #93989e;
}

.react-google-flight-datepicker .dialog-footer {
  flex-direction: row-reverse;
  align-items: center;
  padding: 8px;
  display: flex;
}

.react-google-flight-datepicker .dialog-footer .submit-button {
  text-transform: uppercase;
  color: #fff;
  height: 36px;
  min-width: 88px;
  cursor: pointer;
  background: #4285f4;
  border: none;
  border-radius: 8px;
  padding: 10px 8px;
  font-size: .9rem;
  font-weight: 500;
  line-height: 16px;
}

@media screen and (max-width: 768px) {
  .react-google-flight-datepicker .date-picker-demo {
    width: 100%;
  }

  .react-google-flight-datepicker .date-picker-input {
    height: 40px;
    border: none;
    border-radius: 0;
    position: relative;
  }

  .react-google-flight-datepicker .date {
    border: 1px solid #dadce0;
    border-radius: 5px;
    flex-grow: 1;
    padding: 0 10px;
  }

  .react-google-flight-datepicker .date:nth-child(2) {
    margin-left: 10px;
  }

  .react-google-flight-datepicker .date.is-focus {
    height: 40px;
  }

  .react-google-flight-datepicker .date:first-child:before, .react-google-flight-datepicker .icon-calendar {
    display: none;
  }

  .react-google-flight-datepicker .icon-calendar.mobile {
    display: block;
  }

  .react-google-flight-datepicker .reset-button {
    display: none;
  }

  .react-google-flight-datepicker .reset-button.mobile {
    color: #000000de;
    min-width: 88px;
    font-weight: 500;
    display: block;
  }

  .react-google-flight-datepicker .change-date-group, .react-google-flight-datepicker .divider, .react-google-flight-datepicker .calendar-flippers, .react-google-flight-datepicker .weekdays {
    display: none;
  }

  .react-google-flight-datepicker .back-button {
    width: 56px;
    height: 100%;
    margin-right: 16px;
    padding: 0;
    display: block;
  }

  .react-google-flight-datepicker .back-button svg {
    width: 20px;
    height: 20px;
    fill: #000000b3;
  }

  .react-google-flight-datepicker .dialog-date-picker {
    width: 100%;
    height: 100%;
    max-height: 100%;
    opacity: 1;
    z-index: 999999999;
    border-radius: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    inset: 100% 0 10px;
    transform: none;
  }

  .react-google-flight-datepicker .dialog-date-picker.open {
    transition: all .2s ease-out;
    animation: none;
    transform: translate(0, -100%);
  }

  .react-google-flight-datepicker .dialog-date-picker.hide {
    transition: all .2s ease-in;
    animation: none;
    transform: translate(0);
  }

  .react-google-flight-datepicker .dialog-header {
    z-index: 50;
    padding: 8px;
    box-shadow: 0 0 2px #0000001f, 0 2px 2px #0000003d;
  }

  .react-google-flight-datepicker .dialog-header .date-picker-input {
    height: 40px;
    width: 100%;
  }

  .react-google-flight-datepicker .dialog-content {
    height: 100%;
    border: none;
    overflow: hidden;
  }

  .react-google-flight-datepicker .calendar-wrapper {
    height: 100%;
    margin: 0;
    display: flex;
  }

  .react-google-flight-datepicker .calendar-content {
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;
  }

  .react-google-flight-datepicker .weekdays.mobile {
    background-color: #f3f3f3;
    display: flex;
  }

  .react-google-flight-datepicker .month-calendar {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .react-google-flight-datepicker .day {
    width: 14.2857%;
    max-width: 14.2857%;
  }

  .react-google-flight-datepicker .day.selected:hover, .react-google-flight-datepicker .day.hovered:hover {
    color: #fff !important;
  }

  .react-google-flight-datepicker .day.selected:hover:after, .react-google-flight-datepicker .day.hovered:hover:after {
    background-color: #4285f4 !important;
  }

  .react-google-flight-datepicker .month-name {
    text-align: left;
    margin-top: 25px;
    margin-bottom: 0;
    margin-left: 10px;
    font-weight: bold;
  }

  .react-google-flight-datepicker .dialog-footer {
    padding: 15px 8px;
    box-shadow: 0 0 4px #0000001f, 0 4px 4px #0000003d;
  }
}

.swal2-popup.swal2-toast {
  box-sizing: border-box;
  pointer-events: all;
  background: #fff;
  grid-template-columns: 1fr 99fr 1fr;
  padding: 1em;
  overflow-y: hidden;
  box-shadow: 0 0 1px #00000013, 0 1px 2px #00000013, 1px 2px 4px #00000013, 1px 3px 8px #00000013, 2px 4px 16px #00000013;
  grid-area: 1 / 1 / 4 / 4 !important;
}

.swal2-popup.swal2-toast > * {
  grid-column: 2;
}

.swal2-popup.swal2-toast .swal2-title {
  text-align: initial;
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-loading {
  justify-content: center;
}

.swal2-popup.swal2-toast .swal2-input {
  height: 2em;
  margin: .5em;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-validation-message {
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-footer {
  margin: .5em 0 0;
  padding: .5em 0 0;
  font-size: .8em;
}

.swal2-popup.swal2-toast .swal2-close {
  width: .8em;
  height: .8em;
  grid-area: 1 / 3 / 99 / 3;
  align-self: center;
  margin: 0;
  font-size: 2em;
}

.swal2-popup.swal2-toast .swal2-html-container {
  text-align: initial;
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-html-container:empty {
  padding: 0;
}

.swal2-popup.swal2-toast .swal2-loader {
  width: 2em;
  height: 2em;
  grid-area: 1 / 1 / 99;
  align-self: center;
  margin: .25em;
}

.swal2-popup.swal2-toast .swal2-icon {
  width: 2em;
  min-width: 2em;
  height: 2em;
  grid-area: 1 / 1 / 99;
  align-self: center;
  margin: 0 .5em 0 0;
}

.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
  display: flex;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  width: 1.375em;
  top: .875em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
  left: .3125em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
  right: .3125em;
}

.swal2-popup.swal2-toast .swal2-actions {
  height: auto;
  justify-content: flex-start;
  margin: .5em 0 0;
  padding: 0 .5em;
}

.swal2-popup.swal2-toast .swal2-styled {
  margin: .25em .5em;
  padding: .4em .6em;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"] {
  width: 1.6em;
  height: 3em;
  border-radius: 50%;
  position: absolute;
  transform: rotate(45deg);
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"][class$="left"] {
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
  top: -.8em;
  left: -.5em;
  transform: rotate(-45deg);
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"][class$="right"] {
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0;
  top: -.25em;
  left: .9375em;
}

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  width: .4375em;
  height: 2.6875em;
  top: 0;
  left: .4375em;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"] {
  height: .3125em;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"][class$="tip"] {
  width: .75em;
  top: 1.125em;
  left: .1875em;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"][class$="long"] {
  width: 1.375em;
  top: .9375em;
  right: .1875em;
}

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-toast-animate-success-line-tip .75s;
}

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-toast-animate-success-line-long .75s;
}

.swal2-popup.swal2-toast.swal2-show {
  animation: swal2-toast-show .5s;
}

.swal2-popup.swal2-toast.swal2-hide {
  animation: swal2-toast-hide .1s forwards;
}

.swal2-container {
  z-index: 1060;
  box-sizing: border-box;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
  grid-template-areas: "top-start top top-end"
                       "center-start center center-end"
                       "bottom-start bottom-center bottom-end";
  padding: .625em;
  transition: background-color .1s;
  display: grid;
  position: fixed;
  inset: 0;
  overflow-x: hidden;
}

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: #0006;
}

.swal2-container.swal2-backdrop-hide {
  background: none !important;
}

.swal2-container.swal2-top-start, .swal2-container.swal2-center-start, .swal2-container.swal2-bottom-start {
  grid-template-columns: minmax(0, 1fr) auto auto;
}

.swal2-container.swal2-top, .swal2-container.swal2-center, .swal2-container.swal2-bottom {
  grid-template-columns: auto minmax(0, 1fr) auto;
}

.swal2-container.swal2-top-end, .swal2-container.swal2-center-end, .swal2-container.swal2-bottom-end {
  grid-template-columns: auto auto minmax(0, 1fr);
}

.swal2-container.swal2-top-start > .swal2-popup {
  align-self: start;
}

.swal2-container.swal2-top > .swal2-popup {
  grid-column: 2;
  place-self: start center;
}

.swal2-container.swal2-top-end > .swal2-popup, .swal2-container.swal2-top-right > .swal2-popup {
  grid-column: 3;
  place-self: start end;
}

.swal2-container.swal2-center-start > .swal2-popup, .swal2-container.swal2-center-left > .swal2-popup {
  grid-row: 2;
  align-self: center;
}

.swal2-container.swal2-center > .swal2-popup {
  grid-area: 2 / 2;
  place-self: center;
}

.swal2-container.swal2-center-end > .swal2-popup, .swal2-container.swal2-center-right > .swal2-popup {
  grid-area: 2 / 3;
  place-self: center end;
}

.swal2-container.swal2-bottom-start > .swal2-popup, .swal2-container.swal2-bottom-left > .swal2-popup {
  grid-area: 3 / 1;
  align-self: end;
}

.swal2-container.swal2-bottom > .swal2-popup {
  grid-area: 3 / 2;
  place-self: end center;
}

.swal2-container.swal2-bottom-end > .swal2-popup, .swal2-container.swal2-bottom-right > .swal2-popup {
  grid-area: 3 / 3;
  place-self: end;
}

.swal2-container.swal2-grow-row > .swal2-popup, .swal2-container.swal2-grow-fullscreen > .swal2-popup {
  width: 100%;
  grid-column: 1 / 4;
}

.swal2-container.swal2-grow-column > .swal2-popup, .swal2-container.swal2-grow-fullscreen > .swal2-popup {
  grid-row: 1 / 4;
  align-self: stretch;
}

.swal2-container.swal2-no-transition {
  transition: none !important;
}

.swal2-popup {
  box-sizing: border-box;
  width: 32em;
  max-width: 100%;
  color: #545454;
  background: #fff;
  border: none;
  border-radius: 5px;
  grid-template-columns: minmax(0, 100%);
  padding: 0 0 1.25em;
  font-family: inherit;
  font-size: 1rem;
  display: none;
  position: relative;
}

.swal2-popup:focus {
  outline: none;
}

.swal2-popup.swal2-loading {
  overflow-y: hidden;
}

.swal2-title {
  max-width: 100%;
  color: inherit;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  margin: 0;
  padding: .8em 1em 0;
  font-size: 1.875em;
  font-weight: 600;
  position: relative;
}

.swal2-actions {
  z-index: 1;
  box-sizing: border-box;
  width: auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 1.25em auto 0;
  padding: 0;
  display: flex;
}

.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(#0000001a, #0000001a);
}

.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(#0003, #0003);
}

.swal2-loader {
  width: 2.2em;
  height: 2.2em;
  border: .25em solid #2778c4;
  border-color: #2778c4 #0000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 1.875em;
  animation: swal2-rotate-loading 1.5s linear infinite;
  display: none;
}

.swal2-styled {
  margin: .3125em;
  padding: .625em 1.1em;
  font-weight: 500;
  transition: box-shadow .1s;
  box-shadow: 0 0 0 3px #0000;
}

.swal2-styled:not([disabled]) {
  cursor: pointer;
}

.swal2-styled.swal2-confirm {
  background: initial;
  color: #fff;
  background-color: #7066e0;
  border: 0;
  border-radius: .25em;
  font-size: 1em;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px #7066e080;
}

.swal2-styled.swal2-deny {
  background: initial;
  color: #fff;
  background-color: #dc3741;
  border: 0;
  border-radius: .25em;
  font-size: 1em;
}

.swal2-styled.swal2-deny:focus {
  box-shadow: 0 0 0 3px #dc374180;
}

.swal2-styled.swal2-cancel {
  background: initial;
  color: #fff;
  background-color: #6e7881;
  border: 0;
  border-radius: .25em;
  font-size: 1em;
}

.swal2-styled.swal2-cancel:focus {
  box-shadow: 0 0 0 3px #6e788180;
}

.swal2-styled.swal2-default-outline:focus {
  box-shadow: 0 0 0 3px #6496c880;
}

.swal2-styled:focus {
  outline: none;
}

.swal2-styled::-moz-focus-inner {
  border: 0;
}

.swal2-footer {
  color: inherit;
  border-top: 1px solid #eee;
  justify-content: center;
  margin: 1em 0 0;
  padding: 1em 1em 0;
  font-size: 1em;
}

.swal2-timer-progress-bar-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  grid-column: auto !important;
}

.swal2-timer-progress-bar {
  width: 100%;
  height: .25em;
  background: #0003;
}

.swal2-image {
  max-width: 100%;
  margin: 2em auto 1em;
}

.swal2-close {
  z-index: 2;
  width: 1.2em;
  height: 1.2em;
  color: #ccc;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 5px;
  justify-content: center;
  justify-self: end;
  align-items: center;
  margin-top: 0;
  margin-bottom: -1.2em;
  margin-right: 0;
  padding: 0;
  font-family: monospace;
  font-size: 2.5em;
  transition: color .1s, box-shadow .1s;
  overflow: hidden;
}

.swal2-close:hover {
  color: #f27474;
  background: none;
  transform: none;
}

.swal2-close:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px #6496c880;
}

.swal2-close::-moz-focus-inner {
  border: 0;
}

.swal2-html-container {
  z-index: 1;
  color: inherit;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
  justify-content: center;
  margin: 1em 1.6em .3em;
  padding: 0;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  overflow: auto;
}

.swal2-input, .swal2-file, .swal2-textarea, .swal2-select, .swal2-radio, .swal2-checkbox {
  margin: 1em 2em 3px;
}

.swal2-input, .swal2-file, .swal2-textarea {
  box-sizing: border-box;
  width: auto;
  color: inherit;
  background: none;
  border: 1px solid #d9d9d9;
  border-radius: .1875em;
  font-size: 1.125em;
  transition: border-color .1s, box-shadow .1s;
  box-shadow: inset 0 1px 1px #0000000f, 0 0 0 3px #0000;
}

.swal2-input.swal2-inputerror, .swal2-file.swal2-inputerror, .swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important;
}

.swal2-input:focus, .swal2-file:focus, .swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: none;
  box-shadow: inset 0 1px 1px #0000000f, 0 0 0 3px #6496c880;
}

.swal2-input::placeholder, .swal2-file::placeholder, .swal2-textarea::placeholder {
  color: #ccc;
}

.swal2-range {
  background: #fff;
  margin: 1em 2em 3px;
}

.swal2-range input {
  width: 80%;
}

.swal2-range output {
  width: 20%;
  color: inherit;
  text-align: center;
  font-weight: 600;
}

.swal2-range input, .swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em;
}

.swal2-input {
  height: 2.625em;
  padding: 0 .75em;
}

.swal2-file {
  width: 75%;
  background: none;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125em;
}

.swal2-textarea {
  height: 6.75em;
  padding: .75em;
}

.swal2-select {
  min-width: 50%;
  max-width: 100%;
  color: inherit;
  background: none;
  padding: .375em .625em;
  font-size: 1.125em;
}

.swal2-radio, .swal2-checkbox {
  color: inherit;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.swal2-radio label, .swal2-checkbox label {
  margin: 0 .6em;
  font-size: 1.125em;
}

.swal2-radio input, .swal2-checkbox input {
  flex-shrink: 0;
  margin: 0 .4em;
}

.swal2-input-label {
  justify-content: center;
  margin: 1em auto 0;
  display: flex;
}

.swal2-validation-message {
  color: #666;
  background: #f0f0f0;
  justify-content: center;
  align-items: center;
  margin: 1em 0 0;
  padding: .625em;
  font-size: 1em;
  font-weight: 300;
  overflow: hidden;
}

.swal2-validation-message:before {
  content: "!";
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  color: #fff;
  text-align: center;
  background-color: #f27474;
  border-radius: 50%;
  margin: 0 .625em;
  font-weight: 600;
  line-height: 1.5em;
  display: inline-block;
}

.swal2-icon {
  box-sizing: content-box;
  width: 5em;
  height: 5em;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  border: .25em solid #000;
  border-radius: 50%;
  justify-content: center;
  margin: 2.5em auto .6em;
  font-family: inherit;
  line-height: 5em;
  position: relative;
}

.swal2-icon .swal2-icon-content {
  align-items: center;
  font-size: 3.75em;
  display: flex;
}

.swal2-icon.swal2-error {
  color: #f27474;
  border-color: #f27474;
}

.swal2-icon.swal2-error .swal2-x-mark {
  flex-grow: 1;
  position: relative;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  width: 2.9375em;
  height: .3125em;
  background-color: #f27474;
  border-radius: .125em;
  display: block;
  position: absolute;
  top: 2.3125em;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
  left: 1.0625em;
  transform: rotate(45deg);
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
  right: 1em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-error.swal2-icon-show {
  animation: swal2-animate-error-icon .5s;
}

.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  animation: swal2-animate-error-x-mark .5s;
}

.swal2-icon.swal2-warning {
  color: #f8bb86;
  border-color: #facea8;
}

.swal2-icon.swal2-warning.swal2-icon-show {
  animation: swal2-animate-error-icon .5s;
}

.swal2-icon.swal2-warning.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-i-mark .5s;
}

.swal2-icon.swal2-info {
  color: #3fc3ee;
  border-color: #9de0f6;
}

.swal2-icon.swal2-info.swal2-icon-show {
  animation: swal2-animate-error-icon .5s;
}

.swal2-icon.swal2-info.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-i-mark .8s;
}

.swal2-icon.swal2-question {
  color: #87adbd;
  border-color: #c9dae1;
}

.swal2-icon.swal2-question.swal2-icon-show {
  animation: swal2-animate-error-icon .5s;
}

.swal2-icon.swal2-question.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-question-mark .8s;
}

.swal2-icon.swal2-success {
  color: #a5dc86;
  border-color: #a5dc86;
}

.swal2-icon.swal2-success [class^="swal2-success-circular-line"] {
  width: 3.75em;
  height: 7.5em;
  border-radius: 50%;
  position: absolute;
  transform: rotate(45deg);
}

.swal2-icon.swal2-success [class^="swal2-success-circular-line"][class$="left"] {
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
  top: -.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success [class^="swal2-success-circular-line"][class$="right"] {
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
  top: -.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success .swal2-success-ring {
  z-index: 2;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: .25em solid #a5dc864d;
  border-radius: 50%;
  position: absolute;
  top: -.25em;
  left: -.25em;
}

.swal2-icon.swal2-success .swal2-success-fix {
  z-index: 1;
  width: .4375em;
  height: 5.625em;
  position: absolute;
  top: .5em;
  left: 1.625em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  z-index: 2;
  height: .3125em;
  background-color: #a5dc86;
  border-radius: .125em;
  display: block;
  position: absolute;
}

.swal2-icon.swal2-success [class^="swal2-success-line"][class$="tip"] {
  width: 1.5625em;
  top: 2.875em;
  left: .8125em;
  transform: rotate(45deg);
}

.swal2-icon.swal2-success [class^="swal2-success-line"][class$="long"] {
  width: 2.9375em;
  top: 2.375em;
  right: .5em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip .75s;
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-animate-success-line-long .75s;
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}

.swal2-progress-steps {
  max-width: 100%;
  background: none;
  flex-wrap: wrap;
  align-items: center;
  margin: 1.25em auto;
  padding: 0;
  font-weight: 600;
}

.swal2-progress-steps li {
  display: inline-block;
  position: relative;
}

.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  width: 2em;
  height: 2em;
  color: #fff;
  text-align: center;
  background: #2778c4;
  border-radius: 2em;
  flex-shrink: 0;
  line-height: 2em;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  color: #fff;
  background: #add8e6;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6;
}

.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  width: 2.5em;
  height: .4em;
  background: #2778c4;
  flex-shrink: 0;
  margin: 0 -1px;
}

[class^="swal2"] {
  -webkit-tap-highlight-color: transparent;
}

.swal2-show {
  animation: swal2-show .3s;
}

.swal2-hide {
  animation: swal2-hide .15s forwards;
}

.swal2-noanimation {
  transition: none;
}

.swal2-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

.swal2-rtl .swal2-close {
  margin-right: initial;
  margin-left: 0;
}

.swal2-rtl .swal2-timer-progress-bar {
  left: auto;
  right: 0;
}

.leave-russia-now-and-apply-your-skills-to-the-world {
  z-index: 1939;
  color: #fff;
  text-align: center;
  background: #20232a;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0 20px;
  display: flex;
  position: fixed;
  inset: 0;
}

.leave-russia-now-and-apply-your-skills-to-the-world div {
  max-width: 560px;
  margin: 10px;
  line-height: 146%;
}

.leave-russia-now-and-apply-your-skills-to-the-world iframe {
  max-width: 100%;
  max-height: 55.5556vmin;
  margin: 16px auto;
}

.leave-russia-now-and-apply-your-skills-to-the-world strong {
  border-bottom: 2px dashed #fff;
}

.leave-russia-now-and-apply-your-skills-to-the-world button {
  z-index: 1940;
  width: 48px;
  height: 48px;
  color: #aaa;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  margin-bottom: -10px;
  margin-right: 10px;
  font-size: 48px;
  font-weight: bold;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
}

.leave-russia-now-and-apply-your-skills-to-the-world button:hover {
  color: #fff;
}

@keyframes swal2-toast-show {
  0% {
    transform: translateY(-.625em)rotateZ(2deg);
  }

  33% {
    transform: translateY(0)rotateZ(-2deg);
  }

  66% {
    transform: translateY(.3125em)rotateZ(2deg);
  }

  100% {
    transform: translateY(0)rotateZ(0deg);
  }
}

@keyframes swal2-toast-hide {
  100% {
    opacity: 0;
    transform: rotateZ(1deg);
  }
}

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    width: 0;
    top: .5625em;
    left: .0625em;
  }

  54% {
    width: 0;
    top: .125em;
    left: .125em;
  }

  70% {
    width: 1.625em;
    top: .625em;
    left: -.25em;
  }

  84% {
    width: .5em;
    top: 1.0625em;
    left: .75em;
  }

  100% {
    width: .75em;
    top: 1.125em;
    left: .1875em;
  }
}

@keyframes swal2-toast-animate-success-line-long {
  0% {
    width: 0;
    top: 1.625em;
    right: 1.375em;
  }

  65% {
    width: 0;
    top: 1.25em;
    right: .9375em;
  }

  84% {
    width: 1.125em;
    top: .9375em;
    right: 0;
  }

  100% {
    width: 1.375em;
    top: .9375em;
    right: .1875em;
  }
}

@keyframes swal2-show {
  0% {
    transform: scale(.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(.95);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes swal2-hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.5);
  }
}

@keyframes swal2-animate-success-line-tip {
  0% {
    width: 0;
    top: 1.1875em;
    left: .0625em;
  }

  54% {
    width: 0;
    top: 1.0625em;
    left: .125em;
  }

  70% {
    width: 3.125em;
    top: 2.1875em;
    left: -.375em;
  }

  84% {
    width: 1.0625em;
    top: 3em;
    left: 1.3125em;
  }

  100% {
    width: 1.5625em;
    top: 2.8125em;
    left: .8125em;
  }
}

@keyframes swal2-animate-success-line-long {
  0% {
    width: 0;
    top: 3.375em;
    right: 2.875em;
  }

  65% {
    width: 0;
    top: 3.375em;
    right: 2.875em;
  }

  84% {
    width: 3.4375em;
    top: 2.1875em;
    right: 0;
  }

  100% {
    width: 2.9375em;
    top: 2.375em;
    right: .5em;
  }
}

@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes swal2-animate-error-x-mark {
  0% {
    opacity: 0;
    margin-top: 1.625em;
    transform: scale(.4);
  }

  50% {
    opacity: 0;
    margin-top: 1.625em;
    transform: scale(.4);
  }

  80% {
    margin-top: -.375em;
    transform: scale(1.15);
  }

  100% {
    opacity: 1;
    margin-top: 0;
    transform: scale(1);
  }
}

@keyframes swal2-animate-error-icon {
  0% {
    opacity: 0;
    transform: rotateX(100deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg);
  }

  100% {
    transform: rotateY(0);
  }
}

@keyframes swal2-animate-i-mark {
  0% {
    opacity: 0;
    transform: rotateZ(45deg);
  }

  25% {
    opacity: .4;
    transform: rotateZ(-25deg);
  }

  50% {
    opacity: .8;
    transform: rotateZ(15deg);
  }

  75% {
    opacity: 1;
    transform: rotateZ(-5deg);
  }

  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
}

body.swal2-height-auto {
  height: auto !important;
}

body.swal2-no-backdrop .swal2-container {
  pointer-events: none;
  background-color: #0000 !important;
}

body.swal2-no-backdrop .swal2-container .swal2-popup {
  pointer-events: all;
}

body.swal2-no-backdrop .swal2-container .swal2-modal {
  box-shadow: 0 0 10px #0006;
}

@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important;
  }

  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden="true"] {
    display: none;
  }

  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important;
  }
}

body.swal2-toast-shown .swal2-container {
  box-sizing: border-box;
  width: 360px;
  max-width: 100%;
  pointer-events: none;
  background-color: #0000;
}

body.swal2-toast-shown .swal2-container.swal2-top {
  inset: 0 auto auto 50%;
  transform: translateX(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  inset: 0 0 auto auto;
}

body.swal2-toast-shown .swal2-container.swal2-top-start, body.swal2-toast-shown .swal2-container.swal2-top-left {
  inset: 0 auto auto 0;
}

body.swal2-toast-shown .swal2-container.swal2-center-start, body.swal2-toast-shown .swal2-container.swal2-center-left {
  inset: 50% auto auto 0;
  transform: translateY(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-center {
  inset: 50% auto auto 50%;
  transform: translate(-50%, -50%);
}

body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  inset: 50% 0 auto auto;
  transform: translateY(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-bottom-start, body.swal2-toast-shown .swal2-container.swal2-bottom-left {
  inset: auto auto 0 0;
}

body.swal2-toast-shown .swal2-container.swal2-bottom {
  inset: auto auto 0 50%;
  transform: translateX(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  inset: auto 0 0 auto;
}

/*# sourceMappingURL=index.53f151be.css.map */
