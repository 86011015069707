// colors
$black: #000000
$defaultBlack: #1F1F1F
$lightGrey: #EDEEF2
$middleGrey: #9A9A9A
$darkGrey: #6F6F6F
$white: #ffffff
$lightBlue: #F1F7FF
$lightBlue70: #E9F1FD70
$blue: #2D78EA
$middleBlue: #7DAAED
$darkBlue: #315EA1
$dirtyBlue: #A0B7D9
$red: #FF0000
$lightRed: #FF5959
$green: #00D23B
$middleGreen: #3DAE5D
$orange: #FFC700

*
	margin: 0
	padding: 0

#app
	min-height: 100%
	display: flex
	flex-direction: column

.header-container
	display: flex
	border-bottom: 1px solid $lightGrey

	.header-exit-container
		display: flex
		align-items: center
		justify-content: center
		padding-right: 24px

	.header-nav-container
		flex: 1
		height: 80px
		width: 100%
		display: flex
		align-items: center
		justify-content: center
		gap: 24px

		.header-nav-link
			color: $black

			&:hover
				color: $darkGrey

.login-container
	flex: 1
	display: flex
	justify-content: center
	align-items: center

	.login-form
		border: 1px solid $blue
		border-radius: 12px
		padding: 24px
		display: flex
		flex-direction: column
		gap: 12px

.profile-page
	flex: 1
	padding: 24px

	.profile-page-change-password-wrapper
		padding-top: 12px
		padding-left: 12px

		.profile-page-change-password-content
			display: flex
			padding-top: 12px
			gap: 12px

			.profile-page-change-password-input
				flex: 1
				padding: 12px
				border: 1px solid $lightGrey
				border-radius: 12px
				background-color: $white

.users-page
	flex: 1
	padding: 24px
	overflow-y: auto

	.users-page-filters-form
		display: flex
		flex-direction: row
		align-items: flex-end
		gap: 12px
		margin-bottom: 24px

		.users-page-filters-form-select-wrapper
			flex: 1
			display: flex
			flex-direction: column

	.users-page-table-wrapper
		overflow-x: auto

		.users-page-table
			width: 100%

			.users-page-table-item
				text-align: center

				.users-page-table-item-cell
					padding: 12px
					min-width: 115px
					max-width: 160px

.shipments-page
	flex: 1
	padding: 24px
	overflow-y: auto

	.shipments-page-filters-form
		display: flex
		flex-direction: row
		align-items: flex-end
		gap: 12px
		margin-bottom: 24px

		.shipments-page-filters-form-select-wrapper
			display: flex
			flex-direction: column
			padding: 6

	.shipments-page-table-wrapper
		overflow-x: auto

		.shipments-page-table
			width: 100%

			.shipments-page-table-item
				text-align: center

				.shipments-page-table-item-cell
					padding: 12px
					min-width: 115px
					max-width: 160px

.button-default
	padding: 12px
	border: 1px solid $lightGrey
	border-radius: 12px
	background-color: $blue
	color: $white

	&:hover
		cursor: pointer

	&:disabled
		background-color: $darkBlue

.input-default
	padding: 12px
	border: 1px solid $lightGrey
	border-radius: 12px
	background-color: $white

.page-pagination
	width: 100%
	text-align: right
	margin-top: 24px

	.page-pagination-pages-count
		margin-right: 24px
		margin-left: 24px

.shipment-full-info
	display: flex
	flex-direction: column

	.shipment-full-info-block
		text-align: left
		width: calc(100% - 6px)
		border-bottom: 1px solid $lightGrey
		padding-top: 12px
		padding-bottom: 12px

		.shipment-full-info-block-title
			width: calc(100% - 6px)
			display: inline-block
			font-size: 20px
			color: $black
			margin-bottom: 8px

		.shipment-full-info-block-text
			width: calc(100% - 6px)
			display: inline-block
			font-size: 18px
			color: $darkGrey
			padding-left: 12px
